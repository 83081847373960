import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import Image from 'components/image/Image';
import React from 'react';

interface Props {
  message: any;
}

export const Message: FC<Props> = ({ message }) => {
  const content = (
    <div style={{ whiteSpace: 'pre-line' }}>
      {message?.content.split('\n').map((line: any, index: any) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );

  if (message.sender_details.type === 'admin')
    return (
      <Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
          {/* <MyAvatar
            isImage={!!message.sender_details.photo_url}
            name={'vidnik'}
            url={message?.sender_details?.photo_url}
          /> */}
          <Image
            sx={{
              borderRadius: '50%',
              width: 34,
              height: 34,
            }}
            component='img'
            loading='lazy'
            src={message?.sender_details?.photo_url}
            name={'vidnik'}
          />
          <Typography sx={{ color: 'primary.main' }}>Vidnik</Typography>
        </Box>
        <Typography sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>{content}</Typography>
      </Box>
    );
  return (
    <Box
      sx={{
        background: '#00000066',
        px: 4,
        py: 2,
        borderRadius: 1,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        {/* <MyAvatar
          isImage={!!message.sender_details.photo_url}
          name={message?.sender_details?.full_name}
          url={message?.sender_details?.photo_url}
        /> */}
        <Image
          sx={{
            borderRadius: '50%',
            width: 34,
            height: 34,
          }}
          component='img'
          loading='lazy'
          src={message?.sender_details?.photo_url}
          name={message?.sender_details?.full_name}
        />
        <Typography>{message?.sender_details?.full_name}</Typography>
      </Box>
      <Typography sx={{ color: 'text.secondary' }}>{content}</Typography>
      <Typography sx={{ color: 'text.secondary', mt: -2, fontSize: '12px' }}>
        {/* <AccessTimeIcon sx={{ fontSize: '12px' }} />{' '} */}
        {formatDistanceToNowStrict(new Date(message?.created_at))} ago
      </Typography>
    </Box>
  );
};
